// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_KVt8h{display:flex;flex-direction:column;height:100%;width:100%}.controls_tPQu\\+{display:flex;justify-content:flex-end}.header_h1MlU{display:flex;justify-content:space-between}tbody:first-child{padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_KVt8h",
	"controls": "controls_tPQu+",
	"header": "header_h1MlU"
};
module.exports = ___CSS_LOADER_EXPORT___;
