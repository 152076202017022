
import ModalCreateList from "./components/ModalCreateList.vue";
import WidgetBlank from "../../../components/Widget/WidgetBlank.vue";
import { getListsQuery } from "~/helpers/apollo/apollo-list-query-helpers.js";

export default {
  name: "Lists",

  components: {
    ModalCreateList,
    WidgetBlank,
  },

  inheritAttrs: false,

  apollo: {
    lists: getListsQuery,
  },

  data() {
    return {
      createListModalVisible: false,
    };
  },

  computed: {
    sortedLists: function () {
      return [...this.lists.lists].sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime());
    },
  },

  methods: {
    navigateToList(listId) {
      this.$router.push({ name: "my-estaid-lists-id", params: { id: listId } });

      this.$amplitude.track({
        event_type: "Open list",
        event_properties: {
          route: "Lists",
        },
      });
    },

    listCreated(listId) {
      this.createListModalVisible = false;
      this.$router.push({ name: "my-estaid-lists-id", params: { id: listId } });
    },
  },
};
