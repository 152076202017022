
import { getUsersListPermissions } from "~/helpers/apollo/apollo-user-query-helpers.js";
import CreateList from "~/graphql/Lists/CreateList.gql";
import Lists from "~/graphql/Lists/Lists.gql";
import { LIST_PERMISSION_PRIVATE, USER_PERMISSION_NONE } from "./constants.js";

import UserPermissions from "./UserPermissions.vue";

export default {
  components: { UserPermissions },

  apollo: {
    users: getUsersListPermissions,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listName: "",
      listDescription: "",
      listPermission: "",
      blockSubmit: false,
    };
  },

  watch: {
    visible(value) {
      this.listName = "";
      this.listDescription = "";
      this.listPermission = LIST_PERMISSION_PRIVATE;
      this.blockSubmit = false;
    },
  },

  methods: {
    createList: function () {
      if (this.listName !== "") {
        this.blockSubmit = true;

        const userPermissions = this.users
          .filter((u) => u.permission !== USER_PERMISSION_NONE)
          .map((u) => {
            return {
              userId: u.id,
              permission: u.permission,
            };
          });

        this.$apollo
          .mutate({
            mutation: CreateList,
            variables: {
              name: this.listName,
              description: this.listDescription,
              privacy: this.listPermission,
              listUserPermissions: userPermissions,
            },
            update: (store, { data: { createList } }) => {
              // Read the data from our cache for this query.
              const data = structuredClone(store.readQuery({ query: Lists }));
              // Add our tag from the mutation to the end
              data.lists.push(createList);
              // Write our data back to the cache.
              store.writeQuery({ query: Lists, data });
            },
          })
          .then((data) => {
            this.$amplitude.track({
              event_type: "Create List",
              event_properties: {
                origin: "list item",
              },
            });

            this.blockSubmit = false;

            this.$emit("list-created", data.data.createList.id);
          });
      }
    },
  },
};
